import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ServerHealth } from 'src/app/domain/enums/application/server-health.enum'
import { environment } from 'src/environments/environment'

const BOREAL_URL = environment.borealURL

@Injectable({
  providedIn: 'root'
})
export class ServerHealthService {

  apiHealthSubject: BehaviorSubject<ServerHealth> = new BehaviorSubject<ServerHealth>(ServerHealth.ONLINE)

  public startMonitoringServerHealth(): void {
    const eventSource = new EventSource(`${BOREAL_URL}/health-checker`)

    eventSource.onmessage = (event) => {
      this.apiHealthSubject.next(((event.data === 'online') ? ServerHealth.ONLINE : ServerHealth.OFFLINE))
    }

    eventSource.onerror = () => {
      this.apiHealthSubject.next(ServerHealth.OFFLINE)
    }
  }
}
