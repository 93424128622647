import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core'

import { PrimeNGConfig } from 'primeng/api'

import { environment } from 'src/environments/environment'
import { ServerHealthService } from './data/services/application/server-health/server-health.service'
import { SocketService } from './data/services/application/socket/socket.service'
import { ThemeService } from './data/services/application/theme/theme.service'
import { ServerHealth } from './domain/enums/application/server-health.enum'
import { Theme } from './domain/enums/application/theme.enum'
import { TypeMessageTape } from './domain/enums/components/message-tape.enum'
import { AuthService } from './infra/auth/auth.service'
import { pt_BR } from './infra/translations/pt_BR.translation'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {

  private primengConfig: PrimeNGConfig = inject(PrimeNGConfig)
  private themeService: ThemeService = inject(ThemeService)
  private socketService: SocketService = inject(SocketService)
  private authService: AuthService = inject(AuthService)
  private serverHealthService: ServerHealthService = inject(ServerHealthService)

  title: string = 'frontend'
  selectedTheme: Theme = Theme.DEFAULT
  
  typeMessageTape: TypeMessageTape = TypeMessageTape.DEFAULT 

  isShowTape: boolean = false
  isShowOfflineScreen: boolean = false

  tapeMessage: string = ''

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    console.log('environment:',environment.default)

    this.primengConfig.ripple = true
    this.primengConfig.setTranslation(pt_BR)

    this.themeService.themeSubject.subscribe((selectedTheme: Theme) =>  this.selectedTheme = selectedTheme)

    this.authService.startIddle()

    this.serverHealthService.startMonitoringServerHealth()
    this.serverHealthService.apiHealthSubject.subscribe((apiState: ServerHealth) => {
      this.isShowOfflineScreen = (apiState === ServerHealth.OFFLINE)
      this.cdr.detectChanges()
    })
  }
  
  public ngOnDestroy(): void {
    this.socketService.disconnect()
  }
}
